<template>
  <ion-item
    lines="none"
    class="flex py-2"
    style="
        background: var(--white100);
        flex-wrap: wrap;
        flex-shrink: 1; display: flex; flex-grow: 1
      "
  >
    <ion-grid class="p-0 w-full">
      <ion-row class="px-0">
        <ion-col v-if="label">
          <p class="flex-1">
            {{ label }}
          </p>
        </ion-col>
        <ion-col class="p-0">
          <div class="flex">
            <div class="flex items-center w-full justify-end flex-wrap">
              <!--            <div class="flex-1" />-->
              <ion-thumbnail
                v-for="(photo, index) in modelValue"
                :key="index"
                class="image-preview relative"
                style="flex-shrink: 0; display: inline-block"
                data-cy="image-preview"
              >
                <a-image
                  v-if="isImageUploadType"
                  :src="getPhotoUrl(photo, { thumbnail: true })"
                  :image-class="'w-full h-full object-cover rounded-md'"
                  data-cy="image"
                  @click="openGallery(index)"
                />
                <div
                  v-if="isPdfUploadType"
                  class="w-full h-full object-cover rounded-md flex justify-center items-center"
                  style="background: #efefef"
                  @click="openPdfViewer(index)"
                >
                  .pdf
                </div>
                <div
                  v-if="isOtherUploadType"
                  class="w-full h-full object-cover rounded-md flex justify-center items-center"
                  style="background: #efefef"
                  @click="downloadInputFile(photo)"
                >
                  file
                </div>

                <div
                  v-if="!instanceOfPhoto(photo)"
                  class="absolute bottom-1 right-1"
                  style="width: 8px; height: 8px; border-radius: 50%; background: green;"
                />
              </ion-thumbnail>

              <AButton
                v-if="!readonly && !isReadonly && !maxAllowed()"
                :btn-primary="true"
                data-cy="button-add-photo"
                class="flex-shrink-0"
                :class="[
                  !extendedButton && isPdfUploadType ? 'pdf-button' : ''
                ]"
                :style="
                  isOtherUploadType || isPdfUploadType ? 'padding-top: 12px; padding-bottom: 12px ' : ''"
                @click="onTakePhoto()"
              >
                <CameraIcon v-if="!extendedButton && isImageUploadType" />

                <span
                  v-if="!extendedButton && ( isOtherUploadType || isPdfUploadType)"
                >+</span>

                <CameraIcon v-if="extendedButton && isImageUploadType" />

                <span
                  v-if="!((modelValue?.length ?? 0) > 0) && extendedButton"
                  class="pl-2 pr-2"
                  data-cy="photo-add-button-text"
                > {{ isPdfUploadType ? t("hzba.buttons.pdfHinzufuegen") : t("hzba.buttons.hinzufuegen") }} </span>
              </AButton>
            </div>
          </div>

          <div
            v-if="minMax"
            class="flex justify-end mt-2 tc-grey-500 text-base mr-2"
          >
            <div v-if="minMax.min === minMax.max">
              genau {{ minMax.min }}
            </div>
            <div v-else>
              <span v-if="minMax.min || minMax.max">(</span>
              <span v-if="minMax.min">min. {{ minMax.min }}</span>
              <span v-if="minMax.min && minMax.max">&nbsp;/&nbsp;</span>
              <span v-if="minMax.max">max. {{ minMax.max }}</span>
              <span v-if="minMax.min || minMax.max">)</span>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">
import { store } from "@/store/store";
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonRow, IonThumbnail, modalController, toastController } from "@ionic/vue";
import { camera, close, trash } from "ionicons/icons";
import { PropType, computed, defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';
//Endpoints
import { deleteFile } from "@/api/UploadApi";
//Models
import StrapiMedia, { instanceOfStrapiImage } from "@/models/photo/strapi-media.model";
//Composables
import { usePhotoRecording } from "@/composables/usePhotoRecording";
//Components
import AButton from "@/components/Base/AButton.vue";
import AImage from "@/components/Base/AImage.vue";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError.vue";
//Helpers
import { getPhotoUrl, instanceOfPhoto } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import { openPdfViewerModal } from "@/utilities/pdf-viewer";
import { getPhotoMinMaxLabel } from "@/utilities/helper";
//Icons
import CameraIcon from '@/components/Icons/CameraIcon.vue';
//Other
import useDownloadHelper from "@/composables/useDownloadHelper";
import useUser from "@/composables/useUser";

export default defineComponent({
  name: "HzbaPhotoInput",
  components: {
    InputError,
    AImage,
    AButton,
    IonItem,
    IonRow,
    IonGrid,
    IonIcon,
    IonCol,
    IonImg,
    IonThumbnail,
    IonButton,
    CameraIcon
  },
  emits: ['update:modelValue', 'change', 'updateSocket'],
  props: {
    modelValue: { type: Object as PropType<(StrapiMedia | Blob)[]>, required: false },
    readonly: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    minMax: { type: Object, default: undefined, },
    errors: { type: Array, default: () => [] },
    uploadType: { type: String, default: undefined }
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const { canDownloadImages } = useUser();
    const modalPhotoView = ref(false);
    const { openPhotoDialog } = usePhotoRecording();
    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] )
    const deviceWidth = computed(() => { return window.innerWidth });
    const extendedButton = computed(() => { return !((props.modelValue?.length ?? 0) > 0) && (deviceWidth.value >= 1024) })
    const isPdfUploadType = computed(() => props.uploadType === 'pdf');
    const isOtherUploadType = computed(() => props.uploadType && props.uploadType !== 'pdf');
    const isImageUploadType = computed(() => props.uploadType === undefined || props.uploadType === null || props.uploadType === "");
    const limit = computed(() => {
      if (!props.minMax?.max) {
        return -1;
      }

      return props.minMax.max - (props.modelValue?.length || 0)
    })
    const { downloadFile } = useDownloadHelper();

    const maxAllowed = () => {
      const reachedMaxAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      if (reachedMaxAllowed) {
        return true
      } else {
        return false
      }
    }
    const onTakePhoto = async () => {
      console.log('onTakePhoto triggered');
      const notAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      // isAllowed = !notAllowed;
      if (notAllowed) {

        const toast = await toastController
            .create({
              message: notAllowed,
              duration: 2000
            })
        return toast.present();
      }
      const photoRes = await openPhotoDialog(t, limit.value, !isImageUploadType.value ? { hidePhoto: true, hideGallery: true, isPdfUploadType: isPdfUploadType.value } : { hideFile: true, isPdfUploadType: isPdfUploadType.value });

      console.log('photoRes success?', photoRes?.success)
      console.log('photoRes data?', photoRes?.data);
        if (photoRes.success && photoRes.data) {

        emit('update:modelValue', [...(props.modelValue ?? []), ...photoRes.data ]);
        emit('change');
        emit('updateSocket')
      }
    };

    function downloadInputFile(file: any) {
      downloadFile(""+getPhotoUrl(file), file.name || 'gsi');
    }


    function openGallery(index: number){
      openBaseModal('gallery', {
        images: props.modelValue,
        isReadonly: isReadonly.value,
        title: t("createProperty.images"),
        downloadEnabled: canDownloadImages.value || isReadonly.value,
        index,
        onDelete: (photo: StrapiMedia | Blob) => {
          if (instanceOfStrapiImage(photo)) {
            deleteFile(photo.id)
          }
          emit('update:modelValue', props.modelValue?.filter((_, i) => i !== index));
          emit('change');
          emit('updateSocket')
          modalController.dismiss();
        },
      },
          () => ({}))
    }

    async function openPdfViewer(index: number) {
      const pdf = props.modelValue && props.modelValue[index];
      if (pdf) {
        openPdfViewerModal(pdf);
      } else {
        console.error('No pdf provided');
      }
    }

    return {
      camera,
      trash,
      close,
      onTakePhoto,
      getPhotoUrl,
      modalPhotoView,
      instanceOfPhoto,
      openGallery,
      isReadonly,
      t,
      extendedButton,
      openPdfViewer,
      maxAllowed,
      isPdfUploadType,
      isOtherUploadType,
      isImageUploadType,
      downloadInputFile
    };
  },
});
</script>

<style scoped lang="scss">
.image-preview {
  width: 50px;
  height: 50px;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 4px;
  --border-radius: 10px;
  object-fit: cover;
}

.item-background-color{
  --ion-item-background: transparent;

  margin-right: -22px;
}

.photo-button {
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  background: #efefef;
}

.photo-button.compact {
  width: 50px;
}

.horizontal-scroll {
  overflow-x: auto;
}

.camera-button-extended {
  border-radius: 36px;
  background: $primary;
  color: var(--white100);
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0px 24px;
}

.pdf-button {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>